import { postCandidateRemarks } from "../apiFunction";
import { mapGetters } from "vuex";
const feedBack = {
  name: "Remarks",

  data() {
    return {
      remarks: [
        {
          question: "How much did you enjoy our question pattern?",
          answer: "",
          type: "star",
        },
        {
          question:
            "How satisfied are you with the time allocated for this test?",
          answer: "",
          type: "star",
        },
        {
          question: "Please rate your overall experience with this test",
          answer: "",
          type: "star",
        },
        {
          question: "What could have been better?",
          answer: "",
          type: "remark",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("sectionVUEX", {
      testDetails: "getTestDetails",
      userID: "getUserID",
    }),
  },
  methods: {
    async complete() {
      try {
        for (let i in this.remarks) {
          delete this.remarks[i].type;
        }
        let payload = {
          topicID: this.testDetails.topicID,
          testID: this.testDetails.testID,
          groupID: this.testDetails.groupID,
          remarks: this.remarks,
          userID: this.userID,
          attemptNo: this.testDetails.attemptNo,
        };

        const res = await postCandidateRemarks(payload);
        console.log(res);
        location.replace("https://www.hire3x.com/");
      } catch (error) {
        location.replace("https://www.hire3x.com/");
        console.log(error);
      }
    },
    leave() {
      this.$router.push({
        path: "/finishtest",
      });
    },
  },
};
export default feedBack;
