<template>
  <div class="mobileScreen-main-container">
    <MobileHeader />

    <div
      style="height: calc(100dvh - 60px)"
      class="d-flex align-items-center justify-content-center"
    >
      <div class=" feedback-back mx-2 my-3">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <img
            class="mt-4"
            src="../../assets/mobile-newUI-imgs/feedbck-icon.svg"
            alt=""
          />

          <div class="fd-head text-center mt-2">Feedback</div>
          <div
            v-for="(i, index) in remarks"
            :key="index"
            class="mt-4 d-flex align-items-center justify-content-center flex-column"
          >
            <div v-if="i.type == 'star'" class="fd-subhead text-center w-85">
              {{ i.question }}
            </div>
            <div v-if="i.type == 'star'" class="rating-input-main mt-2">
              <b-form-rating
                id="rating-lg"
                size="lg"
                class="rating-input w-25"
                v-model="i.answer"
              ></b-form-rating>
            </div>
          </div>

          <div class="" style="width: 90%">
            <div>
              <label class="desc-font">Description</label>
              <b-form-textarea
                class="inputField1"
                id="input-1"
                placeholder="What would have been better?"
                rows="3"
                max-rows="4"
                v-model="remarks[3].answer"
                maxlength="1024"
                @keydown="limitCharacters"
                @keyup="truncateText(remarks[3])"
              >
              </b-form-textarea>
              <!-- <div class="character-count fd-subhead">
              {{ 1024 - remarks[3].answer.length + " characters remaining" }}
            </div> -->
            </div>
          </div>

          <div @click="complete()" class="mt-5 d-flex align-items-center mb-4">
            <div class="submit-font pointer">
              Submit & Take me to website
            </div>
            <div class="ml-3 pointer">
              <img
                src="../../assets/mobileScreenIcons/arrow-narrow-right.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import feedBack from "../../mixins/feedBack";
import MobileHeader from "./MobileHeader.vue";
export default {
  name: "Remarks",
  components: {
    MobileHeader,
  },
  mixins: [feedBack],
  methods: {
    limitCharacters(event) {
      let key = event.which || event.keyCode;
      event.target.value.length >= 1024 &&
        key != 8 &&
        key != 46 &&
        event.preventDefault();
    },
    truncateText(field) {
      field.answer = field.answer.substr(0, 1024);
    },
  },
};
</script>

<style>
.character-count {
  text-align: right;
  font-size: 12px;
}
.rating-input-main .form-control {
  border: unset;
  padding: 0px;
}

.rating-div {
  padding: 20px;
  background: #fbfbfb;
  border: 0.7px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(86, 86, 86, 0.13);
  border-radius: 5px;
}
.rating-heading {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #72738e;
}

.rating-input {
  color: #20639b;
}

.right-image {
  padding: 109px 0px 0px 63px;
}
.b-rating .b-rating-star {
  padding-right: 7px;
}

.rating-input .b-rating-icon svg {
  height: 29px;
  width: 33px;
}

.fieldsetStyle1 {
  color: var(--gray-500, #667085);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

/* new style */
.fd-head {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}

.fd-subhead {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #1e2022;
}

.desc-font {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
}

.submit-font {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #20639b;
}

.feedback-back {
  box-shadow: 0px 8px 50px -2px #10182814;
  border-radius: 12px;
  width: 100%;
  height: calc(100dvh - 100px);
  overflow: auto;
}

.w-85 {
  width: 85%;
}
</style>
