<template>
    <div class="mobileHeader d-flex align-items-center justify-content-between">
        <div>
          <img src="../../assets/mobile-newUI-imgs/mobile-logo.svg" alt="" />
        </div>

        <!-- this should come in test screen based on condition -->
        <div v-if="isTestScreen && hideAndShow" :style="timeinsecondsfornotificationfunc.style" class="mobileScreen-CT-timing-container d-flex align-items-center justify-content-center" style="padding: 8px;">
          <div class="mb-1">
            <img
              v-if="
                timeinsecondsfornotificationfunc.imageName == 'whitetimer'
              "
              src="../../assets/mobileScreenIcons/timer-stopwatch.svg"
              alt=""
            />
              <img v-else src="../../assets/mobileScreenIcons/timer-stopwatch-black.svg" alt="" />
          </div>
          <div class="ml-1">
            <span class="mobileScreen-CT-stopwatch-number" :style="timeinsecondsfornotificationfunc.style">{{ testTimerData }}</span>
          </div>
        </div>
    </div>
</template>

<script>
    export default {
      data() {
        return {
          isTestScreen: false,
        }
      },
      created() {
        if(this.$route.name =="TestScreen"){
          this.isTestScreen = true
        }else{
          this.isTestScreen = false
        }
      },
      props: {
        testTimerData: String,
        hideAndShow:Boolean,
        timeinsecondsfornotificationfunc:Object
    },
    }
</script>
